document.addEventListener("turbo:frame-load", function() {
  // to handle frame reloads from inline edits
  $("#search_results_container").hide()
});

$(document).on("turbo:load", function() {
  $("#search_results_container").hide()

  $("#cypher_search_field").on("keypress", function (e) {
    if(e.key != 'Enter') {
      return
    }

    if(!(e.ctrlKey || e.metaKey) && $("#use_enter_to_search").val() != 'true')
    {
      return
    }

    display_search(
      "/help/" + "search",
      $("#cypher_search_field").val(),
      $("#cypher_search_type").val(),
      $("#cypher_search_limit").val()
    );
  });

  $("#cypher_search_button").on("click", function (e) {
    display_search(
      "/help/" + "search",
      $("#cypher_search_field").val(),
      $("#cypher_search_type").val(),
      $("#cypher_search_limit").val()
    );
  });

  $("#search_results_hide").on("click", function (e) {
    $("#search_results_container").hide()
    $("#cypher_search_field").val('');
  });

  function display_search(get_url, value, search_type, search_limit) {
    var messages = [
        'Spending 3 Int on Draw Conclusion...',
        'Preparing for GM Intrusions...',
        'Using Recall Knowledge cypher on this one...',
        'Reviewing Edge and Effort Rules, again...',
        'Expending Convenient Research cypher...',
        'Hmmm, Understanding only costs 1 Int...',
        'Definitely picked Cypher System as my Flex Skill this morning...',
        'Calculating The Incalculable...',
        'Fusing Mind And Machine...',
        'Translating ancient scrolls for hints..',
        'Navigating the datasphere for insights...',
      ]

    function getRandomMessage() {
      var randomIndex = Math.floor(Math.random() * messages.length);
      return messages[randomIndex];
    }

    $('#spinner').show()
    $("#search_results_title").text('');
    $("#search_results").text(getRandomMessage());
    $("#search_results_container").show()
    // Start showing messages before making the AJAX call
    var messageInterval = setInterval(function() {
      $("#search_results").text(getRandomMessage());
    }, 5000); // Change message every X seconds

    $.ajax({
      type: "GET",
      url: get_url,
      data: {
        value: value,
        search_type: search_type,
        search_limit: search_limit
      },
      dataType: "json",
      success: function (data) {
        $('#spinner').hide()
        clearInterval(messageInterval); // Stop showing messages
        result = data.help;

        $("#search_results_title").text('');
        $("#search_results").html(result);
      },
      error: function (textStatus, errorThrown) {
        $('#spinner').hide()
        clearInterval(messageInterval); // Stop showing messages
        $("#search_results_title").text('Server Error');
        $("#search_results").html("Something feels wrong.<br><br>Flickering torch fades<br>Lost in dungeon's dark embrace<br>Roll initiative");
      }
    });
  }
});
