$(document).on("turbo:load", function () {
  $("#random_portrait").click(function (e) {
    e.preventDefault(); // Prevents the default anchor behavior
    randomizeSelectValue("cypher_character_portrait_age");
    randomizeSelectValue("cypher_character_portrait_build");
    randomizeSelectValue("cypher_character_portrait_skin_tone");
    randomizeSelectValue("cypher_character_portrait_hair_color");
    randomizeSelectValue("cypher_character_portrait_hair_style");
    randomizeSelectValue("cypher_character_portrait_eye_color");
    $("#cypher_character_portrait_clothing_style").val("").trigger("change");
  });

  $("[data-toggle-target]").on("click", function () {
    const targetValue = $(this).data("toggle-target");

    // Toggle content
    const content = $(`[data-toggle-content="${targetValue}"]`);
    content.toggle();

    let isMultiple = false;
    const elements = $(`[data-toggle-multiple-content="${targetValue}"]`);
    const firstElementIsHidden = elements.first().is(":hidden");
    elements.each(function () {
      if (firstElementIsHidden) {
        $(this).show();
      } else {
        $(this).hide();
      }
      isMultiple = true;
    });

    const indicator = $(`[data-toggle-indicator="${targetValue}"]`);
    if (isMultiple || indicator.length > 0) {
      // Check if the indicator exists
      if (content.is(":visible") || (isMultiple && firstElementIsHidden)) {
        indicator.html("&#9661;"); // Content is now visible, so show the "open" indicator
      } else {
        indicator.html("&#9658;"); // Content is now hidden, so show the "closed" indicator
      }
    }
  });

  $("#damage_track_hale").on("change", function (e) {
    update_state_change("hale");
    update_damage_track_help();
  });

  $("#damage_track_impaired").on("change", function (e) {
    update_state_change("impaired");
    update_damage_track_help();
  });

  $("#damage_track_debilitated").on("change", function (e) {
    update_state_change("debilitated");
    update_damage_track_help();
  });

  $("#recovery_rested").on("change", function (e) {
    update_state_change("recovery_rested");
  });

  $("#recovery_one_action").on("change", function (e) {
    update_state_change("recovery_one_action");
  });

  $("#recovery_ten_mins").on("change", function (e) {
    update_state_change("recovery_ten_mins");
  });

  $("#recovery_one_hour").on("change", function (e) {
    update_state_change("recovery_one_hour");
  });

  $("#recovery_ten_hours").on("change", function (e) {
    update_state_change("recovery_ten_hours");
  });

  $("#might_up").on("click", function (e) {
    modify_displayed_value("#character_might", 1);
    autosave();
  });

  $("#might_down").on("click", function (e) {
    modify_displayed_value("#character_might", -1);
    autosave();
  });

  $("#luck_up").on("click", function (e) {
    modify_displayed_value("#character_luck", 1);
    autosave();
  });

  $("#luck_down").on("click", function (e) {
    modify_displayed_value("#character_luck", -1);
    autosave();
  });

  $("#speed_up").on("click", function (e) {
    modify_displayed_value("#character_speed", 1);
    autosave();
  });

  $("#speed_down").on("click", function (e) {
    modify_displayed_value("#character_speed", -1);
    autosave();
  });

  $("#intellect_up").on("click", function (e) {
    modify_displayed_value("#character_intellect", 1);
    autosave();
  });

  $("#intellect_down").on("click", function (e) {
    modify_displayed_value("#character_intellect", -1);
    autosave();
  });

  $("#experience_points_up").on("click", function (e) {
    modify_displayed_value("#character_experience_points", 1);
    autosave();
  });

  $("#experience_points_down").on("click", function (e) {
    modify_displayed_value("#character_experience_points", -1);
    autosave();
  });

  function modify_displayed_value(div_id, int_value) {
    current = $(div_id).text();
    val = parseInt(current);
    val += int_value;
    $(div_id).text(val);
    update_damage_track();
  }

  function update_damage_track() {
    might = parseInt($("#character_might").text()) <= 0 ? 1 : 0;
    speed = parseInt($("#character_speed").text()) <= 0 ? 1 : 0;
    intellect = parseInt($("#character_intellect").text()) <= 0 ? 1 : 0;

    total_zero = might + speed + intellect;

    current_state = $("input[name=damage_track]:checked").prop("id");

    if (total_zero == 0) {
      if (current_state != "damage_track_hale") {
        $("#damage_track_hale").prop("checked", true);
        update_damage_track_help();
        update_state_change("hale");
      }
      return;
    }

    if (total_zero == 1) {
      if (current_state != "damage_track_impaired") {
        $("#damage_track_impaired").prop("checked", true);
        update_damage_track_help();
        update_state_change("impaired");
      }
      return;
    }

    if (current_state == "damage_track_debilitated") {
      return;
    }
    $("#damage_track_debilitated").prop("checked", true);
    update_damage_track_help();
    update_state_change("debilitated");
  }

  function update_damage_track_help() {
    current_state = $("input[name=damage_track]:checked").prop("id");
    speed = parseInt($("#character_speed").text());

    if (current_state == "damage_track_hale") {
      $("#impaired_help").hide();
      $("#debilitated_speed_positive").hide();
      $("#debilitated_speed_zero").hide();
    }

    if (current_state == "damage_track_impaired") {
      $("#impaired_help").show();
      $("#debilitated_speed_positive").hide();
      $("#debilitated_speed_zero").hide();
    }

    if (current_state == "damage_track_debilitated") {
      $("#impaired_help").show();
      if (speed <= 0) {
        $("#debilitated_speed_zero").show();
      } else {
        $("#debilitated_speed_positive").show();
      }
    }
  }

  var autosave_timer = null;

  function update_state_change(attribute) {
    clearTimeout(autosave_timer);
    $.ajax({
      type: "POST",
      url: window.location.href + "/autosave",
      data: {
        might: $("#character_might").text(),
        speed: $("#character_speed").text(),
        intellect: $("#character_intellect").text(),
        luck: $("#character_luck").text(),
        experience_points: $("#character_experience_points").text(),
        money: $("#character_money").text(),
        attribute_state_change: attribute,
      },
      accept: {
        javascript: "application/javascript",
      },
      dataType: "json",
      success: function (data) {
        // all good
      },
    });
  }

  function autosave() {
    clearTimeout(autosave_timer);
    autosave_timer = setTimeout(function () {
      console.log("autosaving");
      $.ajax({
        type: "POST",
        url: window.location.href + "/autosave",
        data: {
          might: $("#character_might").text(),
          speed: $("#character_speed").text(),
          intellect: $("#character_intellect").text(),
          luck: $("#character_luck").text(),
          experience_points: $("#character_experience_points").text(),
          money: $("#character_money").text(),
        },
        accept: {
          javascript: "application/javascript",
        },
        dataType: "json",
        success: function (data) {
          // all good
        },
      });
    }, 2500);
  }

  function randomizeSelectValue(selectId) {
    const selectElement = $("#" + selectId);
    if (selectElement.length === 0) return; // Exit if select element not found

    const options = selectElement.find("option");
    if (options.length === 0) return; // Exit if there are no options

    // Generate a random index (excluding the first option if it's a placeholder)
    const randomIndex = Math.floor(Math.random() * options.length);

    // Set the value to the value of the option at the random index
    selectElement.val(options.eq(randomIndex).val()).trigger("change");
  }
});
