$(document).on("turbo:load", function () {
  $("#character_upgrade_capabilities").on("click", function (e) {
    $("#character_upgrade_capabilities_choices").toggle();
    handle_state_change();
  });

  $("#character_upgrade_perfection").on("click", function (e) {
    $("#character_upgrade_perfection_choices").toggle();
    handle_state_change();
  });

  $("#character_upgrade_effort").on("click", function (e) {
    $("#character_upgrade_effort_choices").toggle();
    handle_state_change();
  });

  $("#character_upgrade_skill").on("click", function (e) {
    $("#character_upgrade_skill_choices").toggle();
    handle_state_change();
  });

  $("#might_pool_up").on("click", function (e) {
    modify_displayed_value("#character_might_pool", 1);
  });

  $("#might_pool_down").on("click", function (e) {
    modify_displayed_value("#character_might_pool", -1);
  });

  $("#speed_pool_up").on("click", function (e) {
    modify_displayed_value("#character_speed_pool", 1);
  });

  $("#speed_pool_down").on("click", function (e) {
    modify_displayed_value("#character_speed_pool", -1);
  });

  $("#intellect_pool_up").on("click", function (e) {
    modify_displayed_value("#character_intellect_pool", 1);
  });

  $("#intellect_pool_down").on("click", function (e) {
    modify_displayed_value("#character_intellect_pool", -1);
  });

  $("#character_upgrade_skill_choice").on("change", function (e) {
    display_skill_selection(e.target.value);
  });

  function display_skill_selection(skill_name) {
    $("#character_upgrade_skill_skill_choice").hide();
    $("#character_upgrade_skill_armor_choice").hide();
    $("#character_upgrade_skill_recovery_roll_choice").hide();
    $("#character_upgrade_skill_ability_choice").hide();
    $(`#character_upgrade_skill_${skill_name}_choice`).show();

    if (skill_name == "ability") {
      display_help(
        "/help/" + "ability",
        $("#select_character_upgrade_skill_ability_choice").val().split("__")[0]
      );
    }
  }

  function handle_state_change() {
    update_xp_cost();
    display_upgrade_abilities();
    display_skill_selection("skill");
  }

  function update_xp_cost() {
    cost = 0;
    if (
      $("#character_upgrade_capabilities").is(":checked") &&
      !$("#character_upgrade_capabilities").is(":disabled")
    ) {
      cost += 4;
    }
    if (
      $("#character_upgrade_perfection").is(":checked") &&
      !$("#character_upgrade_perfection").is(":disabled")
    ) {
      cost += 4;
    }
    if (
      $("#character_upgrade_effort").is(":checked") &&
      !$("#character_upgrade_effort").is(":disabled")
    ) {
      cost += 4;
    }
    if (
      $("#character_upgrade_skill").is(":checked") &&
      !$("#character_upgrade_skill").is(":disabled")
    ) {
      cost += 4;
    }

    $("#xp_cost_to_advance_display").text(cost);
    $("#xp_cost_to_advance").val(cost);
  }

  function display_upgrade_abilities() {
    if (
      ($("#character_upgrade_capabilities").length == 0 ||
        $("#character_upgrade_capabilities").is(":checked")) &&
      ($("#character_upgrade_perfection").length == 0 ||
        $("#character_upgrade_perfection").is(":checked")) &&
      ($("#character_upgrade_effort").length == 0 ||
        $("#character_upgrade_effort").is(":checked")) &&
      ($("#character_upgrade_skill").length == 0 ||
        $("#character_upgrade_skill").is(":checked"))
    ) {
      $("#character_upgrade_ability_choices").show();
      return;
    }

    $("#character_upgrade_ability_choices").hide();
  }

  function modify_displayed_value(div_id, int_value) {
    available = parseInt($("#pool_to_spend").text());

    if (available < 1 && int_value == 1) {
      return;
    }

    if (available >= 4 && int_value == -1) {
      return;
    }

    /* check the val > current pool and don't decrement */

    current = $(div_id).text();
    val = parseInt(current);
    val += int_value;

    if (div_id == "#character_might_pool") {
      if (val < starting_might_pool) {
        return;
      }
      $("#might_pool_increase").val(val);
    }

    if (div_id == "#character_speed_pool") {
      if (val < starting_speed_pool) {
        return;
      }
      $("#speed_pool_increase").val(val);
    }

    if (div_id == "#character_intellect_pool") {
      if (val < starting_intellect_pool) {
        return;
      }
      $("#intellect_pool_increase").val(val);
    }

    $(div_id).text(val);
    $("#pool_to_spend").text(available - int_value);
  }

  function display_help(get_url, value) {
    $.ajax({
      type: "GET",
      url: get_url,
      data: { value: value },
      dataType: "json",
      success: function (data) {
        result = data.help;
        if (result == "") {
          title = "Help";
        }

        $("#context_help").text(result);
      },
    });
  }

  $("#character_upgrade_skill_ability_choice").on("change", function (e) {
    display_help("/help/ability", e.target.value.split("__")[0]);
  });

  // Setup initial state
  handle_state_change();
});
